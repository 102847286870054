import React from "react";
import logo from "./logo.svg";
import "./App.css";
import { Spinner } from "@blueprintjs/core";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { useAuth } from "./auth";
import Login from "./Login";
import { Home } from "./Home";
import "@blueprintjs/core/lib/css/blueprint.css";
import "@blueprintjs/icons/lib/css/blueprint-icons.css";
import { Header } from "./Header";
import { Prompts } from "./Prompts";
import { History } from "./History";
function App() {
  const { isLoadingUser, authUser } = useAuth();
  if (isLoadingUser) return <Spinner />;
  if (authUser === null) return <Login />;
  console.log(authUser);
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/home" element={<Home />} />
        <Route path="/prompts" element={<Prompts />} />
        <Route path="/history" element={<History />} />

        <Route path="/*" element={<Navigate to="/home" />} />
      </Routes>
    </Router>
  );
}

export default App;
