import { Button, Card, Divider, EditableText, InputGroup } from "@blueprintjs/core";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { fireStore } from "./firebase";

export const Prompts = () => {
  const [docData, setDocData] = useState<any>(null);
  const saveData = async () => {
    await setDoc(
      doc(fireStore, "SENTIMENT_CHAT_PROMPTS", "main"),
      {
        ...docData,
      },
      { merge: true }
    );
  };
  useEffect(() => {
    const ref = doc(fireStore, "SENTIMENT_CHAT_PROMPTS", "main");
    getDoc(ref).then((doc) => {
      setDocData(doc.data());
    });
  }, []);
  if (!docData || !docData.list) return null;

  return (
    <div className="container">
      <Card>
        <div style={{ display:"flex",justifyContent:"space-between",width:"100%" }}>
            <b>Prompt uzunluğu sorgu başına maliyeti arttıracaktır!</b>
          <Button
            intent="primary"
            onClick={() => {
              saveData();
            }}
          >
            Kaydet
          </Button>
        </div>
        <Divider/>
        <ul style={{ paddingLeft: 10 }}>
          {docData.list.map((item: any, index: number) => {
            return (
              <li>
                <EditableText
                  onChange={(e) => {
                    docData.list[index] = e;
                    setDocData({ ...docData });
                  }}
                  minLines={3}
                  multiline
                  value={item}
                  placeholder="Prompt giriniz"
                />
              </li>
            );
          })}
        </ul>
        <Button
          onClick={() => {
            docData.list.push("");
            setDocData({ ...docData });
          }}
        >
          Ekle
        </Button>
      </Card>
    </div>
  );
};
