import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
export const firebaseConfig = {
  apiKey: 'AIzaSyB_DadiPKlPLIt3sw_uPFr6i3KxI1WDiIs',
  authDomain: 'algo-senti.firebaseapp.com',
  databaseURL: 'https://algo-senti.firebaseio.com',
  projectId: 'algo-senti',
  storageBucket: 'algo-senti.appspot.com',
  messagingSenderId: '109562175983',
  appId: '1:109562175983:web:66ed44b4c1feae44dbd61a',
};

const defaultProject = initializeApp(firebaseConfig);
export const fireStore = getFirestore(defaultProject);
export const auth = getAuth(defaultProject);
export const storage = getStorage(defaultProject);
