import { User } from 'firebase/auth';
import React, { createContext, useContext } from 'react';
import { useProvideAuth } from './hooks/use-auth-provider';
const authContext = createContext<{
  authUser: User | null;
  logingIn: boolean;
  isLoadingUser:boolean;
  userLogin: (
    values: { email: string; password: string },
    onSuccess: () => void,
    onError: (err: { code: string }) => void
  ) => void;
  userLoginWithGoogle: (
    
    onSuccess: () => void,
    onError: (err: { code: string }) => void
  ) => void;
  userSignup: (
    values: { email: string; password: string },
    onSuccess: () => void,
    onError: (err: { code: string }) => void
  ) => void;
}>({
  authUser: null,
  logingIn: false,
  isLoadingUser:false,
  userLogin: (
    values: { email: string; password: string },
    onSuccess: (err: string) => void,
    onError: (err: { code: string }) => void
  ) => {},
  userLoginWithGoogle: (
    onSuccess: (err: string) => void,
    onError: (err: { code: string }) => void
  ) => {},
  userSignup: (
    values: { email: string; password: string },
    onSuccess: () => void,
    onError: (err: { code: string }) => void
  ) => {},
});

export function AuthProvider({ children }: any) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
  return useContext(authContext);
};
