import { getRef } from "@blueprintjs/core";
import {
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  onAuthStateChanged,
  signInAnonymously,
  signInWithEmailAndPassword,
  User,
  UserCredential,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";

import { auth, fireStore } from "../firebase";

export const useProvideAuth = () => {
  const [authUser, setAuthUser] = useState<User | null>(null);
  const [error, setError] = useState("");
  const [logingIn, setLogingIn] = useState(false);
  const [signingUp, setSigningUp] = useState(false);
  const [isLoadingUser, setLoadingUser] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [googleProvider, setGoogleProvider] =
    useState<GoogleAuthProvider | null>(null);
  useEffect(() => {
    const unlisten = onAuthStateChanged(auth, function (user: User | null) {
      if (user) {
        const ref = doc(fireStore, "SENTIMENT_CHAT_USERS", user.uid);

        getDoc(ref).then((doc) => {
          if (doc.exists()) {
            setLoadingUser(false);

            setAuthUser(user);
          } else {
            setLoadingUser(false);
          }
        });
      } else {
        setLoadingUser(false);

        setAuthUser(user);
      }
    });

    return () => {
      unlisten();
    };
  }, []);
  const userLoginWithGoogle = (
    onSuccess: () => void,
    onError?: (error: any) => void
  ) => {};
  const userLogin = (
    user: { email: string; password: string },
    onSuccess: () => void,
    onError?: (error: any) => void
  ) => {
    setLogingIn(true);
    setTimeout(() => {
      signInWithEmailAndPassword(auth, user.email, user.password)
        .then((user: UserCredential) => {
          setLogingIn(false);
          setAuthUser(user.user);
          onSuccess();
        })
        .catch((err: any) => {
          setLogingIn(false);
          if (onError) onError(err);
        });
    }, 0);
  };

  const userSignup = (
    user: { email: string; password: string },
    onSuccess: () => void,
    onError?: (error: any) => void
  ) => {
    setSigningUp(true);
    createUserWithEmailAndPassword(auth, user.email, user.password)
      .then((user: UserCredential) => {
        setSigningUp(false);
        setAuthUser(user.user);
        onSuccess();
      })
      .catch((err: any) => {
        setSigningUp(false);
        if (onError) onError(err);
      });
  };

  const renderSocialMediaLogin = () => null;

  const userSignOut = () => {
    auth.signOut();
  };

  const getAuthUser = () => {
    return auth?.currentUser;
  };

  // Return the user object and auth methods
  return {
    logingIn,
    isLoadingUser,
    isLoading,
    authUser,
    error,
    setError,
    setAuthUser,
    getAuthUser,
    userLogin,
    userSignup,
    userSignOut,
    renderSocialMediaLogin,
    userLoginWithGoogle,
  };
};
