import { Alignment, Button, Navbar } from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { useProvideAuth } from "./hooks/use-auth-provider";

export const Header = () => {
  const { userSignOut } = useProvideAuth();
  const navigate = useNavigate();
  return (
    <Navbar style={{ marginBottom: 10 }}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading style={{cursor:"pointer"}} onClick={() => navigate("/home")}>
          Sentiment Chat
        </Navbar.Heading>
      </Navbar.Group>
      <Navbar.Group align={Alignment.RIGHT}>
        <Button
          minimal
          icon="home"
          text="Anasayfa"
          onClick={() => navigate("/home")}
        />
        <Button
          minimal
          icon="predictive-analysis"
          text="Promptlar"
          onClick={() => navigate("/prompts")}
        />
        <Button
          minimal
          icon="history"
          text="Geçmiş"
          onClick={() => navigate("/history")}
        />
        <Button
          onClick={() => {
            userSignOut();
          }}
          minimal
          icon="log-out"
          text="Çıkış"
        />
      </Navbar.Group>
    </Navbar>
  );
};
