import {
  Button,
  Card,
  Divider,
  EditableText,
  InputGroup,
  Slider,
  Spinner,
} from "@blueprintjs/core";
import axios from "axios";
import { useState } from "react";
import { auth } from "./firebase";

export const Home = () => {
  const [message, setMessage] = useState("");
  const [answer, setAnswer] = useState("");
  const [token, setToken] = useState<any>({});
  const [cost, setCost] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [temperature, setTemperature] = useState(0.7);
  const sendData = async () => {
    setLoading(true);
    setError(null);
    const token = await auth.currentUser?.getIdToken();
    axios
      .post(
        `${process.env.REACT_APP_SENTIMENT_CHAT_API_URL}/chat`,
        { message: message,temperature },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        setAnswer(res.data.result);
        setToken(res.data.token);
        setCost(res.data.cost);
        setLoading(false);
      })
      .catch((e) => {
        setError(e?.message ? `Hata oluştu: ${e.message}` : `Hata oluştu`);
        setLoading(false);
      });
  };
  return (
    <div className="container">
      <Card>
        <div className="container-card" style={{ display: "flex", flexDirection: "column", gap: 20 }}>
          <div>
            <h4>Prompt:</h4>
            <InputGroup
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  sendData();
                }
              }}
              placeholder="Sentiment algo nedir?"
            ></InputGroup>
          </div>
          <div>
            <h4>
              Yaratıcılık<small> (Büyük değerler daha yaratıcı)</small>
            </h4>
            <Slider
              stepSize={0.1}
              max={2}
              min={0}
              value={temperature}
              onChange={(e) => setTemperature(e)}
            />
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <Button onClick={() => sendData()} intent="primary">
              Gönder
            </Button>
          </div>
        </div>
        {error && <div>{error}</div>}
        {loading && <Spinner />}
        {!loading && (
          <>
            <div style={{ marginBottom: 40 }}>
              <h3>Cevap:</h3>
              <div>{answer}</div>
            </div>
            <h3>Detaylar</h3>

            <table>
              <tr>
                <td>
                  <b>Prompt Token:</b>
                </td>
                <td>{token.prompt}</td>
              </tr>
              <tr>
                <td>
                  <b>Cevap Token:</b>
                </td>
                <td>{token.completion}</td>
              </tr>
              <tr>
                <td>
                  <b>Toplam Token:</b>
                </td>
                <td>{token.total}</td>
              </tr>
              <tr>
                <td>
                  <b>Maliyet:</b>
                </td>
                <td>
                  {cost.toFixed(4)}$/{(cost * 19.5).toFixed(4)} TL
                </td>
              </tr>
            </table>
          </>
        )}
      </Card>
    </div>
  );
};
