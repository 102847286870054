import { Button, Card, Divider, EditableText, InputGroup } from "@blueprintjs/core";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  setDoc,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { fireStore } from "./firebase";

export const History = () => {
  const [history, setHistory] = useState<any>([]);

  useEffect(() => {
    const q = query(
      collection(fireStore, "SENTIMENT_CHAT_HISTORY"),
      limit(100),
      orderBy("dateTime", "desc")
    );

    getDocs(q).then((querySnapshot) => {
      const data = querySnapshot.docs.map((doc) => doc.data());
      setHistory(data);
    });
  }, []);

  return (
    <div className="container">
      <ul style={{ paddingLeft: 10 }}>
        {history.map((item: any, index: number) => {
          return (
            <Card>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 5,
                  width: "100%",
                }}
              >
                <div>
                  <b>Q:</b> {item.message}
                </div>
                <div>
                  <b>A:</b> {item.result}
                </div>
                <Divider/>
                <div>
                  <b>Maliyet:</b> {item.cost.toFixed(4)}$/
                  {(item.cost * 19.5).toFixed(4)} TL
                </div>
                <div>
                  <b>Prompt Token:</b> {item.promptToken}
                </div>

                <div>
                  <b>Cevap Token:</b> {item.completionToken}
                </div>
                <div>
                  <b>Toplam Token:</b> {item.totalToken}
                </div>
                <div>
                  <b>Yaratıcılık:</b> {item.temperature.toFixed(1)}
                </div>
              </div>
            </Card>
          );
        })}
      </ul>
    </div>
  );
};
